import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '../../components/Drawer/Drawer';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import { setTheme } from './actions'; //, setCurrency, setLanguage, setTimeZone
import '../../styles/appColors.scss';
import './Settings.scss';
import { 
    List, 
    ListItem, 
    ListItemText, 
    Switch, 
    // Select, 
    // MenuItem, 
    // FormControl, 
    // InputLabel,
    ListItemIcon,
    Tooltip
} from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import LanguageIcon from '@mui/icons-material/Language';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Settings = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleThemeChange = () => {
        dispatch(setTheme(settings.theme === 'light' ? 'dark' : 'light'));
    };

    // const handleCurrencyChange = (event) => {
    //     dispatch(setCurrency(event.target.value));
    // };

    // const handleLanguageChange = (event) => {
    //     dispatch(setLanguage(event.target.value));
    // };

    // const handleTimezoneChange = (event) => {
    //     dispatch(setTimeZone(event.target.value));
    // };

    return (
        <div>
            <TopBar toggleDrawer={toggleDrawer} />
            <Drawer open={drawerOpen} onClose={toggleDrawer} />
            <main>
                <h1>Settings</h1>
                <List className="settings-list">
                    
                        <ListItem className="settings-item">
                            <ListItemIcon>
                                {settings.theme === 'dark' ? 
                                <DarkModeIcon /> :
                                <LightModeIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Theme" />
                            <Tooltip title={settings.theme === 'dark' ? 'Toggle Light Mode' : 'Toggle Dark Mode'}>
                                <Switch
                                    checked={settings.theme === 'dark'}
                                    onChange={handleThemeChange}
                                    className="theme-switch"
                                />
                            </Tooltip>
                        </ListItem>
                    
                    {/* <ListItem className="settings-item">
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Currency" />
                        <FormControl>
                            <Select
                                value={settings.currency}
                                onChange={handleCurrencyChange}
                                className="currency-select"
                            >
                                <MenuItem value="USD">USD</MenuItem>
                                <MenuItem value="EUR">EUR</MenuItem>
                                <MenuItem value="GBP">GBP</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className="settings-item">
                        <ListItemIcon>
                            <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Language" />
                        <FormControl>
                            <Select
                                value={settings.language}
                                onChange={handleLanguageChange}
                                className="language-select"
                            >
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="es">Spanish</MenuItem>
                                <MenuItem value="fr">French</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className="settings-item">
                        <ListItemIcon>
                            <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Timezone" />
                        <FormControl>
                            <Select
                                value={settings.timeZone}
                                onChange={handleTimezoneChange}
                                className="timezone-select"
                            >
                                <MenuItem value="UTC">UTC</MenuItem>
                                <MenuItem value="EST">EST</MenuItem>
                                <MenuItem value="PST">PST</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem> */}
                </List>
            </main>
            <Footer />
        </div>
    );
}

export default Settings;