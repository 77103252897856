//template

const initialState = {
    loginData: null,
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOGIN_DATA':
            return { ...state, loginData: action.payload };
        default:
            return state;
    }
};
