// UnderConstruction.jsx
import React from 'react';
import './UnderConstruction.scss';

const UnderConstruction = () => {
  return (
    <div className="under-construction-container">
      <div className="tape-container">
        <div className="tape tape-top"></div>
        <div className="tape tape-bottom"></div>
      </div>
      <div className="text-container">
        <h1 className="main-text">UNDER CONSTRUCTION</h1>
        <p className="sub-text">COMING SOON</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
