import React, { useState } from 'react';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import Drawer from '../../components/Drawer';
import { TextField, Button, Typography, Box, useTheme } from '@mui/material';
import { sendEmail } from '../../utils/emailer';
// import { sendContactEmail } from '../../utils/emailer';
import './Contact.scss';

const Contact = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const theme = useTheme();
  const MAX_MESSAGE_LENGTH = 300;
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  const validateForm = (formData) => {
    const { name, email, subject, message } = formData;
    if (!name || !email || !subject || !message) {
      throw new Error('Please fill in all fields');
    }
    // if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    //   throw new Error('Please enter a valid email address');
    // }
    if (message.length > MAX_MESSAGE_LENGTH) {
      throw new Error(`Message exceeds maximum length of ${MAX_MESSAGE_LENGTH} characters`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: name,
        email: email,
        subject: subject,
        message: message
      };
      validateForm(formData);
      sendEmail(formData);
      // Reset form
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      // alert('Message sent successfully!');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="contact">
      <TopBar toggleDrawer={toggleDrawer} />
      <Drawer open={drawerOpen} onClose={toggleDrawer}/>
      <main className="contact__main">
        <div className="contact__main__header">
          <Typography variant="h2" gutterBottom>Contact Us</Typography>
        </div>
        <div className="contact__main__content">
          <Typography variant="body1" paragraph>
            Please fill out the form below if you want to reach out and we'll get back to you as soon as possible.
        </Typography>
        </div>
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto' }} theme={theme}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            
          />
          <TextField
            fullWidth
            margin="normal"
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            
          />
          <TextField
            fullWidth
            margin="normal"
            label={`Message (max ${MAX_MESSAGE_LENGTH} chars)`}
            multiline
            rows={4}
            value={message}
            onChange={(e) => {
              if (e.target.value.length <= MAX_MESSAGE_LENGTH) {
                setMessage(e.target.value);
              }
            }}
            required
            helperText={`${message.length}/${MAX_MESSAGE_LENGTH} characters`}
          />
          <Button type="submit" variant="contained" className="contact__main__btn">
            Send Message
          </Button>
        </Box>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
