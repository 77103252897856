//template
const initialState = {
    theme: 'light',
    language: 'en',
    currency: 'EUR',
    timeZone: 'UTC',
};


export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_THEME':
            return {
                ...state,
                theme: action.payload
            };
        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.payload
            };
        case 'SET_CURRENCY':
            return {
                ...state,
                currency: action.payload
            };
        case 'SET_TIME_ZONE':
            return {
                ...state,
                timeZone: action.payload
            };
        default:
            return state;
    }
}