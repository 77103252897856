// TopBar.jsx
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import './TopBar.scss';

const TopBar = ({toggleDrawer}) => {
  const location = useLocation();
  
  const getPathName = () => {
    if (location.pathname === '/') {
      return 'home';
    }
    return location.pathname.split('/').pop();
  }
  return (
    <AppBar position="sticky" className="top-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Absolute Logic Labs - {getPathName()}
          </Typography>
        </Toolbar>
      </AppBar>
  );
};

export default TopBar;