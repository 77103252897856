import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logAnalyticsEvent } from '../firebase';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logAnalyticsEvent('page_view', { page_path: location.pathname });
  }, [location]);
};