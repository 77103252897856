import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Create the ThemeContext with default light mode
const ThemeContext = createContext();

// Export a custom hook for accessing the theme context
export const useTheme = () => useContext(ThemeContext);

// ThemeProvider Component
export const ThemeProvider = ({ children }) => {
  // State to hold the current theme, with 'light' as the default value
  const storedTheme = useSelector((state) => state.settings.theme)
  const [theme, setTheme] = useState(storedTheme);

  // Load the stored theme from localStorage if available
  useEffect(() => {
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, [storedTheme]);

  // Save the theme choice in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('settings.theme', theme);    
    // Apply the theme to the root of the document (body or html)
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
