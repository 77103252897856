// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCw6dYK2ozXlgQiuQPkb-HZPA1BfINFuKU",
  authDomain: "absolute-logic-labs.firebaseapp.com",
  projectId: "absolute-logic-labs",
  storageBucket: "absolute-logic-labs.appspot.com",
  messagingSenderId: "976485675832",
  appId: "1:976485675832:web:349fe07290a64554a287cd",
  measurementId: "G-T5DKY1SF2F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

// Helper function to log events
export const logAnalyticsEvent = (eventName, eventParams = {}) => {
    logEvent(analytics, eventName, eventParams);
  };

// Function to check if Firebase is initialized
export const checkFirebaseConnection = () => {
  if (app) {
    console.log("Firebase is initialized and connected");
    // Log a test event to verify analytics
    logAnalyticsEvent('test_connection', { message: 'Firebase connection successful' });
    return true;
  } else {
    console.error("Firebase is not initialized");
    return false;
  }
};