import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../app/store';
import Drawer from '../../components/Drawer';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import UnderConstruction from '../../components/UnderConstruction';
// import { usePageTracking } from '../../utils/usePageTracking';

import './Home.scss';

const Home = () => {
  const storeData = store.getState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const storedUserName = useSelector(state => state.home.userName) || null;

  useEffect(() => {
    // Example of getting data from the store
  }, [storeData, storedUserName]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // usePageTracking();

  return (
    <div className="home">
      <TopBar toggleDrawer={toggleDrawer} />
      <Drawer open={drawerOpen} onClose={toggleDrawer}/>
      <main className="home__main">
        <section className="home__section">
          <h1 className="home__title">Welcome to Absolute Logic Labs!</h1>
          <p className="home__subtitle">Software Development Collective</p>
        </section>
        <section className="home__section">
          <div className="home__coming-soon">
            <UnderConstruction />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
