// Drawer.jsx
import React from 'react';
import { Drawer as MuiDrawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import './Drawer.scss';


const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        // textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

const Drawer = ({ open, onClose }) => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        const currentPath = window.location.pathname;
        onClose();
        setTimeout(() => {
            navigate(path, { state: { prevPath: currentPath } });
        }, 100); // Adjust this delay as needed
    };
    
    return (
        <MuiDrawer
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { width: 250 }
            }}
            className="drawer"
        >
            <List className="drawer__list">
                <StyledListItem onClick={() => handleNavigation('/')}>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                </StyledListItem>
                <StyledListItem onClick={() => handleNavigation('/contact')}>
                    <ListItemIcon><ContactsIcon /></ListItemIcon>
                    <ListItemText primary="Contact" />
                </StyledListItem>
                <StyledListItem onClick={() => handleNavigation('/settings')}>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary="Settings" />
                </StyledListItem>
            </List>
        </MuiDrawer>
    );
};

export default Drawer;
