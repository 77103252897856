import emailjs from '@emailjs/browser';

const COOLDOWN_PERIOD = 15 * 60 * 1000; // 15 minutes in milliseconds
let lastEmailSentTime = 0;

export const sendEmail = (formData) => {
    const currentTime = Date.now();
    if (currentTime - lastEmailSentTime < COOLDOWN_PERIOD) {
        const remainingTime = Math.ceil((COOLDOWN_PERIOD - (currentTime - lastEmailSentTime)) / 60000);
        alert(`Please wait ${remainingTime} minutes before sending another email.`);
        return;
    }

    const transformFormData = (formData) => {
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        subject: formData.subject,
        message: formData.message
      };
      
      // Create a temporary form element
      const form = document.createElement('form');
      
      // Add input fields for each parameter
      Object.entries(templateParams).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
      
      return form;
    };

    const transformedData = transformFormData(formData);
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      transformedData,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    )
      .then((result) => {
          console.log(result.text);
          alert('Email sent successfully');
          lastEmailSentTime = Date.now();
          // Handle success (e.g., show a success message)
      }, (error) => {
          console.log(error);
          alert('Error sending email');
          // Handle error (e.g., show an error message)
      });
  };