// Footer.jsx
import React from 'react';
import { Typography, Box } from '@mui/material';
import './Footer.scss';

const Footer = () => {
  return (
    <Box component="footer" className="footer">
      <Typography variant="body2" className="footer-text">
        &copy; {new Date().getFullYear()} Absolute Logic Labs. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;