//template

const initialState = {
    homeData: 'Basic data here',
    userName: ''
};

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_HOME_DATA':
            return { ...state, homeData: action.payload };

        case 'SET_USER_NAME':
            return { ...state, userName: action.payload };
        default:
            return state;
    }
};
