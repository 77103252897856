//template

export const setTheme = (theme) => ({
    type: 'SET_THEME',
    payload: theme
});

export const setLanguage = (language) => ({
    type: 'SET_LANGUAGE',
    payload: language
});

export const setCurrency = (currency) => ({
    type: 'SET_CURRENCY',
    payload: currency
}); 

export const setTimeZone = (timeZone) => ({
    type: 'SET_TIME_ZONE',
    payload: timeZone
});
