import React, { useState } from 'react';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import Drawer from '../../components/Drawer';
import './NotFound.scss';

const NotFound = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div>
      <TopBar toggleDrawer={toggleDrawer} />
      <Drawer open={drawerOpen} onClose={toggleDrawer}/>
      <main>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you're looking for doesn't exist.</p>
      </main>
      <Footer />
    </div>
  );
};

export default NotFound;
