//template

const initialState = {
    contactData: 'Contact data here',
};

export const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CONTACT_DATA':
            return { ...state, contactData: action.payload };
        default:
            return state;
    }
};
