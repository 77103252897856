import { configureStore } from '@reduxjs/toolkit';
import { homeReducer } from '../pages/Home/reducer';
import { contactReducer } from '../pages/Contact/reducer';
import { loginReducer } from '../pages/Login/reducer';
import { settingsReducer } from '../pages/Settings/reducer';
// Function to load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Function to save state to localStorage
const saveState = (state) => {
  try {
    console.log('State to be saved:', state);
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.error('Error saving state:', error);
  }
};

const preloadedState = loadState();

// Create the store without subscribing to it
export const store = configureStore({
  reducer: {
    home: homeReducer,
    contact: contactReducer,
    login: loginReducer,
    settings: settingsReducer,
  },
  preloadedState,
});

// Subscribe to the store after it's created
store.subscribe(() => {
  console.log('State to be saved:', store.getState());
  saveState(store.getState());
});
